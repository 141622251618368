// https://pt.stackoverflow.com/a/123742

function validateCPF(inputCPF: string){
  var soma = 0;
  var resto;
  const cpfDigits = inputCPF.replace(/[^\d]/gi, '');

  if(cpfDigits === '00000000000') return false;
  for(let i=1; i<=9; i++) soma = soma + parseInt(cpfDigits.substring(i-1, i)) * (11 - i);
  resto = (soma * 10) % 11;

  if((resto === 10) || (resto === 11)) resto = 0;
  if(resto !== parseInt(cpfDigits.substring(9, 10))) return false;

  soma = 0;
  for(let i = 1; i <= 10; i++) soma = soma + parseInt(cpfDigits.substring(i-1, i))*(12-i);
  resto = (soma * 10) % 11;

  if((resto === 10) || (resto === 11)) resto = 0;
  if(resto !== parseInt(cpfDigits.substring(10, 11))) return false;
  return true;
}

export default validateCPF;