import { useState, useEffect } from "react";

function useScroll(pxAwayFromTop: number) {

const [isScrolling, setScroll] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      let currentPosition = document.documentElement.scrollTop;
      if (currentPosition > pxAwayFromTop) {
        return setScroll(true);
      } else if (currentPosition > pxAwayFromTop + 300) {
        return;
      }
      return setScroll(false);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [isScrolling, pxAwayFromTop]);
  return { isScrolling }
}

export default useScroll;