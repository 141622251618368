import axios from "axios";
import { API_FILTER_PARTNERS } from "../context/CampaignContext/static";
import { Especialidade } from "./AxiosRequestEspecialidade";

export interface Parceiro {
  Bairro: string,
  Cep: string,
  Cidade: string,
  CodigoCategoria: number,
  CodigoEmpresa: number,
  Complemento: string,
  ContatoTelefone: string,
  Email: string,
  IdEmpresaEndereco: number,
  Latitude: string,
  Logradouro: string,
  Longitude: string,
  NomeFantasia: string,
  NomeImagem: string,
  Numero: string,
  PorcentagemMaximaDesconto: number,
  Uf: string,
  Unidade: string,
}

export class AxiosRequestParceiros {
  async get (especialidade: Especialidade, uf: string, nomeCidade: string): Promise<Parceiro[]> {
    const params = {
      codigoCategoria: especialidade.Codigo,
      uf,
      nomeCidade,
      nome: ''
    }
    const response = await axios.get(API_FILTER_PARTNERS, { params });
    return response.data.ObjetoRetorno.map((o:any) => o as Parceiro);
  }
}