import { ErrorObject } from "ajv";
import { DataValidationCxt } from "ajv/dist/types";
import { Context, Dispatch, SetStateAction, useContext, useState } from "react";
import ajv from "../schemas/validation";

function useAjvValidationWithCtx<T, K extends { errors: Record<string, string>, userInputs: Record<string, string>}>(
  ctx: Context<{ updateFormData: Dispatch<SetStateAction<T>>, formData: K }>,
  schemaName: string,
  field?: keyof K["userInputs"],
  initialData?: { errors: Record<string, any> },
) {
  const { updateFormData, formData } = useContext(ctx);
  const [isValid, setIsValid] = useState(false);

  const setAllInputsErrors = (errorsDict: any) => {
    return updateFormData((oldValues: any) => {
      return {
        ...oldValues,
        errors: errorsDict,
      };
    });
  };

  const resetAllInputErrors = () => {
    updateFormData((oldValues: any) => {
      return {
        ...oldValues,
        ...(initialData?.errors || {}),
      };
    });
  };

  const setOneInputError = (newError: string) => {
    field &&
      updateFormData((oldValues: any) => {
        return {
          ...oldValues,
          errors: {
            ...oldValues.errors,
            [field]: newError,
          },
        };
      });
  };

  const resetOneInputError = () => {
    setIsValid(true);
    field &&
      updateFormData((oldValues: any) => {
        return {
          ...oldValues,
          errors: {
            ...oldValues.errors,
            [field]: "",
          },
        };
      });
  };

  const getError = (
    selectedField: keyof K["userInputs"],
    errorObj: ErrorObject
  ) => {
    const testField = (field: string) => errorObj.schemaPath.includes(field);
    const hasErrorMessage =
      errorObj.keyword === "errorMessage" &&
      (testField(`${String(selectedField)}/errorMessage`) ||
        testField(`/properties/${String(selectedField)}/errorMessage`));
    return hasErrorMessage && (errorObj.message || "Dados inválidos");
  };

  // Get specific user input constraints ajv using #[id].
  //   [id] is equals to the [name] of property at Ctx and
  //   [name] is equals to the [name] of input too
  const checkInput = (selectedField: keyof K["userInputs"]) => {
    const inputSchemaName = `${schemaName}#${String(selectedField)}`
      const isValidInput = ajv.validate(
        { $ref: inputSchemaName },
        formData.userInputs?.[String(selectedField)] as unknown as DataValidationCxt<string>,
      );
      if (!isValidInput) {
        // Get custom input error message
        const inputError = ajv.errors?.find((errorObj) => {
          return getError(selectedField, errorObj);
        });
        inputError?.message && setOneInputError(inputError.message);
        return setIsValid(false);
      }
    // }
    // Input válido
    setOneInputError("");
    return setIsValid(true);
  };

  // Check all user inputs and fill errors message
  const checkSchemaAtFormCtx = () => {
    const validate = ajv.getSchema(schemaName);

    if (validate) {
    const isValidSchema = validate(formData?.userInputs);
    if (!isValidSchema) {
      const errorsDict = validate.errors?.reduce((errorObj, errorRaised) => {
        const selectedField = errorRaised.instancePath.substring(
          1
        );
        if (
          (errorObj &&
          typeof errorObj?.[selectedField] !== "string") ||
          errorObj?.[selectedField].length === 0
        )
          return {
            ...errorObj,
            [selectedField]: getError(selectedField, errorRaised),
          };
        return errorObj;
      }, initialData?.["errors"] || {});
      errorsDict && setAllInputsErrors(errorsDict);
      return setIsValid(false);
    }
  }
    // Context em userInputs válido
    resetAllInputErrors();
    return setIsValid(true);
  };

  const checkIsValid = () => {
    if (field) {
      return checkInput(field);
    } else if (!field) {
      return checkSchemaAtFormCtx();
    }
  };

  return {
    isValid: isValid,
    checkIsValid,
    resetAllInputErrors,
    resetOneInputError,
  };
}

export default useAjvValidationWithCtx;
