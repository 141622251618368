import axios from "axios";
import { API_LIST_STATES_BY_CATEGORY } from "../context/CampaignContext/static";
import { Especialidade } from "./AxiosRequestEspecialidade";

export class AxiosRequestCidade {
  async get (especialidade: Especialidade, uf: string): Promise<string[]> {
    const params = {
      codigoCategoria: especialidade.Codigo,
      uf
    }
    const response = await axios.get(API_LIST_STATES_BY_CATEGORY, { params });
    return response.data.ObjetoRetorno.map((o:any) => o as string);
  }
}