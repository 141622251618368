import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from "react";

export type IUserInputs = {
  subject: string;
  name: string;
  email: string;
  phone: string;
  cep: string;
  cpf: string;
  msg: string;
};

export type IFormData = { userInputs: IUserInputs } & {
  errors: { [key in keyof IUserInputs]: string };
};

export type IFormCtx = {
  updateFormData: Dispatch<SetStateAction<IFormData>>,
  formData: IFormData
};

type IFormProvider = ({ children }: { children: ReactNode }) => JSX.Element;

export const INITIAL_FORM_DATA = {
  userInputs: {
    name: '',
    email: '',
    phone: '',
    cep: '',
    cpf: '',
    msg: '',
    subject: '',
  },
  errors: {
    name: '',
    email: '',
    phone: '',
    cep: '',
    cpf: '',
    msg: '',
    subject: '',
  },
};

const INITIAL_FORM_CTX: IFormCtx = {
  updateFormData: (() =>
    console.log("Context not found")) as unknown as Dispatch<
    SetStateAction<IFormData>
  >,
  formData: INITIAL_FORM_DATA,
};

export const ContactFormContext = createContext(INITIAL_FORM_CTX);

const { Provider: CtxProvider } = ContactFormContext;


export const ContactFormProvider: IFormProvider = ({ children }) => {
  const [formData, updateFormData] = useState<IFormData>(INITIAL_FORM_DATA);
  const formContext = {
    formData,
    updateFormData,
  };

  return <CtxProvider value={formContext}>{children}</CtxProvider>;
};
