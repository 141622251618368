import React, {
  Context,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  useContext,
} from "react";
import {
  IFormData,
} from "../../context/ContactFormDataContext";
import useValidInputCtx from "../../hooks/useValidInputCtx";
import * as S from "./style";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: keyof IFormData["userInputs"];
  type: HTMLInputTypeAttribute;
  schemaName: string;
}

function CustomInput<K extends Context<any>>(
  { type = "text", name, ctx, schemaName, ...customParam }: InputProps & { ctx: K }) {
  const { formData } = useContext(ctx);
  const { inputParams: { onChange, onFocus, onBlur }, error } = useValidInputCtx(ctx, schemaName, name);

  return (
    <>
      <S.Input
        className={error ? "input-error" : ""}
        type={type}
        name={name}
        onFocus={onFocus}
        onChange={onChange}
        onBlur={onBlur}
        value={formData.userInputs[name]}
        {...customParam}
      />
      {error && <span className="error-icon">!</span>}
    </>
  );
}

export default CustomInput;
