export class FindNextGameRepository {
  axiosAdapter = null
  apiUri = null

  constructor (axiosAdapter, apiUri) {
    this.axiosAdapter = axiosAdapter
    this.apiUri = apiUri
  }

  async find (params) {
    const response = await this.axiosAdapter.get(this.apiUri, {
      params: {
        teamId: params.teamId
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return response
  }
}