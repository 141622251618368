import * as S from "./style";
import contato from "../../assets/svgs/contato-headset.svg";

import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {
  ContactFormContext,
  IUserInputs,
} from "../../context/ContactFormDataContext";
import useAjvValidationWithCtx from "../../hooks/useAjvValidationWithCtx";
import CustomInput from "../../components/CustomInput";
import { FormEventHandler, useContext, useEffect, useState } from "react";
import CustomSelect from "../../components/CustomSelect";
import CustomTextArea from "../../components/CustomTextArea";

export default function Contato() {
  const {
    formData: { errors },
  } = useContext(ContactFormContext);

  const { checkIsValid, isValid } = useAjvValidationWithCtx(ContactFormContext, "order");
  const [hasErrors, updateHasErrors] = useState(false);

  const handleSubmit: FormEventHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        checkIsValid();
      }

  const renderErrors = () => {
    const errorElements: (boolean | JSX.Element)[] =
      errors &&
      Object.keys(errors).map((name) => {
        const errorString = errors[name as keyof IUserInputs]
        if (errorString.length > 0) {
            return (
              <div key={name}>
                <span className="error-msg">
                  {errorString}
                </span>
                <span className="error-icon">!</span>
              </div>
            );
        }
        return false;
      });
    return errorElements;
  };

  useEffect(() => {
    const checkErrors = Object.keys(errors).map(
      (error) => errors[error as keyof IUserInputs] !== ""
    );
    if (checkErrors.includes(true)) {
        return updateHasErrors(true);
    }
    updateHasErrors(false);
    return;
  }, [errors]);

  return (
    <S.ContatoContainer>
      <Header />
      <S.Principal>
        <div className="grid">
          <div className="contato">
            <img src={contato} alt="logo contato" />
            <div className="fones">
              <h1>
                ENTRE EM
                <br />
                CONTATO
              </h1>
              <p>Central de Vendas:</p>
              <a href="tel:08007238899">0800 723 8899</a>
              <p>SAC:</p>
              <a href="tel:08002838916">0800 283 8916</a>
            </div>
          </div>

          <div className="form">
            <form action="">
              <CustomSelect ctx={ContactFormContext} name="subject" schemaName="order">
                <option value="" disabled hidden>
                  Selecione o assunto
                </option>
                <option value="solicitacao">Solicitação</option>
                <option value="reclamacao">Reclamação</option>
                <option value="informacao">Informação</option>
                <option value="outros">Outros</option>
              </CustomSelect>

              <CustomInput ctx={ContactFormContext} schemaName="order" type="text" name="name" placeholder="*Nome" />
              <CustomInput ctx={ContactFormContext} schemaName="order" type="text" name="email" placeholder="*E-mail" />
              <CustomInput ctx={ContactFormContext} schemaName="order" type="text" name="cpf" placeholder="*CPF" />
              <CustomInput ctx={ContactFormContext} schemaName="order" type="text" name="phone" placeholder="*Telefone" />
              <CustomInput ctx={ContactFormContext} schemaName="order" type="text" name="cep" placeholder="*CEP" />
              <CustomTextArea ctx={ContactFormContext} schemaName="order" name="msg" />
              {hasErrors && !isValid && renderErrors()}
              <button
                onClick={handleSubmit}
                type="button"
              >
                ENVIAR
              </button>
            </form>
          </div>
        </div>
      </S.Principal>

      <Footer />
    </S.ContatoContainer>
  );
}
