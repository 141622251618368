import { Col, Container, Row } from 'react-bootstrap';

import * as S from './style';
import amorSaude from '../../assets/svgs/logo-preto-amorsaude.svg'
import cna from '../../assets/svgs/logo-preto-cna.svg'
import drogasil from '../../assets/svgs/logo-preto-drogasil.svg'
import estacio from '../../assets/svgs/logo-preto-estacio.svg'
import raia from '../../assets/svgs/logo-preto-raia.svg'
import refuturiza from '../../assets/svgs/logo-preto-refuturiza.svg'

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Form from '../../components/Form';
import { Parceiro } from '../../domain/AxiosRequestParceiros';
import { useContext, useEffect, useState } from 'react';
import ParceiroComponent from '../../components/ParceiroComponent';
import useAjvValidationWithCtx from '../../hooks/useAjvValidationWithCtx';
import { INITIAL_FORM_DATA, ISearchUserInputs, SearchFormContext } from '../../context/SearchFormDataContext';
import { useCampaign } from '../../context/CampaignContext';

export default function Home() {
    const [parceiros, setParceiros] = useState<Parceiro[]>()
    const {
        formData: { errors },
        updateFormData,
      } = useContext(SearchFormContext);
    const { isValid } = useAjvValidationWithCtx(SearchFormContext, "search");
    const [hasErrors, updateHasErrors] = useState(false);
    const { campaignData } = useCampaign();
  
    useEffect(() => {
      updateFormData(INITIAL_FORM_DATA);
    }, [updateFormData])

    const renderErrors = () => {
        const errorElements: (boolean | JSX.Element)[] =
          errors &&
          Object.keys(errors).map((name) => {
            const errorString = errors[name as keyof ISearchUserInputs]
            if (errorString.length > 0) {
                return (
                  <div key={name}>
                    <span className="error-msg">
                      {errorString}
                    </span>
                    <span className="error-icon">!</span>
                  </div>
                );
            }
            return false;
          });
        return errorElements;
      };
    
      useEffect(() => {
        const checkErrors = Object.keys(errors).map(
          (error) => errors[error as keyof ISearchUserInputs] !== ""
        );
        if (checkErrors.includes(true)) {
            return updateHasErrors(true);
        }
        updateHasErrors(false);
        return;
      }, [errors]);

    return<>
        <Header translucent={false} />
        <S.Rede>
            <h1>Nossos parceiros mais perto de você</h1>
            <Container>
                <Row>
                    <Col md='2' xs='6'>
                        <img src={amorSaude} alt="logo amor e saúde" />
                        <p>consulta a partir<br />de <span>{campaignData.prices.appointment.general}</span></p>
                    </Col>
                    <Col md='2' xs='6'>
                        <img src={cna} alt="logo CNA" />
                        <p><span>35%</span> no 1º estágio e<br /><span>15%</span> no 2º estágio</p>
                    </Col>
                    <Col md='2' xs='6'>
                        <img src={drogasil} alt="logo Drogasil" />
                        <p>até <span>35%</span> em<br />medicamentos</p>
                    </Col>
                    <Col md='2' xs='6'>
                        <img src={estacio} alt="Logo Estácio" />
                        <p><span>40%</span> nos cursos de<br />graduação e pós-graduação</p>
                    </Col>
                    <Col md='2' xs='6'>
                        <img src={raia} alt="Logo Droga Raia" />
                        <p>até <span>35%</span> em<br />medicamentos</p>
                    </Col>
                    <Col md='2' xs='6'>
                        <img src={refuturiza} alt="Logo Refuturiza" />
                        <p><span>{campaignData.prices.partners.refuturiza}</span> por mês</p>
                    </Col>
                </Row>
            </Container>
        </S.Rede>

        <S.Unidades>
            <Form setParceiros={setParceiros} />
            <div className="error-container">
              {hasErrors && !isValid && renderErrors()}
            </div>
            <Container>
                <Row>
                    {parceiros?.map(parceiro => <ParceiroComponent {...parceiro} />)}
                </Row>
            </Container>
        </S.Unidades>

        <Footer />
    </>
}