import styled from "styled-components";

export const TextArea = styled.textarea`
  position: relative;
  border-radius: 10px;
  width: 100%;
  border: none;
  margin: 0 0 0.75rem;
  color: #707070;
  font-size: 0.875rem;
  outline: none;
  background-color: #fcf8ef;
  border: none;
  padding-left: 25px;
  box-sizing: border-box;
  overflow: visible;
  &::placeholder {
    font-style: italic;
    color: rgba(130, 130, 130, 1);
  }
  &.input-error {
    box-shadow: 0 0 0 1px var(--accent);
  }
  & + .error-icon {
    text-align: center;
    display: block;
    font-weight: 700;
    color: var(--error-icon-txt-secondary);
    background-color: var(--error-icon-bg-primary);
    min-height: 1.375rem;
    min-width: 1.375rem;
    border-radius: 50%;
    margin-top: -2.813rem;
    padding-top: 0.125rem;
    position: absolute;
    margin-left: 30.625rem;
    margin-left: clamp(24rem, 50.634vw, 30.925rem);
    @media (max-width: 48em) {
      margin-left: clamp(13rem, 70.54vw, 22.3rem);
    }
  }
`;
