import styled from "styled-components";

export const Rede = styled.div`
    text-align: center;
    padding: 50px 0;
    padding-top: 150px;
    h1{
        color: var(--primary-text);
        font-family: 'ubuntu';
        font-weight: bold;
        font-size: 2.5rem;
        line-height: 40px;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 60px;
        @media (max-width: 768px){
            font-size: 17px;
            line-height: 20px;
            margin-bottom: 30px;
            width: 20ch;
            margin: auto;
            margin-bottom: 1rem;
        }
    }
    p{
        font-family: 'ubuntu';
        font-weight: 500;
        color: var(--parceiro-header-txt);
        font-size: 14px;
        line-height: 18px;
        margin-top: 15px;
        span{
            font-weight: bold;
            color: var(--parceiro-header-txt);
        }
    }
    @media (max-width: 48rem) {
        padding-top: 100px;
        padding-bottom: 0px;
        .col-md-2 > img {
            margin-top: 1.25rem;
        }
    }
`;

export const Unidades = styled.div`
    padding: 30px 0;
    .unidade{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 366px;
        text-align: center;
        border: solid 1px #CCCCCC;
        padding: 20px 40px;
        margin: 20px 0;
        h4{
            font-family: 'ubuntu';
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            margin: 20px 0;
        }
        a{
            color: #000;
            font-family: 'ubuntu';
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-decoration: none;
        }
        button, a.local {
            min-width: 200px;
            line-height: 24px;
            border-radius: 30px;
            background-color: var(--parceiro-btn-bg);
            border: none;
            color: var(--btn-primary-txt);
            margin-top: 20px;
            position: relative;
            padding: 10px 0;
            img{
                position: absolute;
                right: 20px;
            }
        }
        .logo {
            display: block;
            margin: 0 auto;
            overflow: hidden;
            border: 1px solid #ccc;
            border-radius: 50%;
            width: 92px;
            height: 92px;
        }
    }
    form button {
        background-color: var(--search-icon-bg-secondary);
        svg {
            fill: var(--search-icon-txt-secondary);
        }
    }
    .error-container {
        max-width: 58.75rem;
        margin-inline: auto;
    }
    .error-msg {
        font-family: "Ubuntu", sans-serif;
        display: inline-flex;
        padding-left: 3rem;
        align-items: center;
        max-width: 58.625rem;
        width: 100%;
        min-height: 2.75rem;
        background-color: var(--error-msg-bg);
        color: var(--error-msg-txt);
        margin-top: 0.625rem;
        border-radius: 1.938rem;
        font-weight: 700;
        & + .error-icon {
            text-align: center;
            display: block;
            font-weight: 700;
            color: var(--error-icon-txt-secondary);
            background-color: var(--error-icon-bg-primary);
            min-height: 1.375rem;
            min-width: 1.375rem;
            border-radius: 50%;
            margin-top: -2.113rem;
            padding-top: 0.125rem;
            margin-left: 0.625rem;
            position: absolute;
        }        
    }
    @media (max-width: 48rem) {
        padding-top: 0;
    }

    & + div {
        margin-top: 0;
        padding-top: 5rem;
        @media (max-width: 80em) {
            margin-top: 0%;
            padding-top: 3rem;
        }
    }
`;