"use client";

import { createContext, ReactNode, useContext, useState } from "react";
import { Prices } from "../utils/prices";

// Definição do tipo de estado global
interface GlobalState {
  cdtPrices: Prices;
}

// Estado inicial global
const initialState: GlobalState = {
  cdtPrices: {
    valor: "30,90",
    clinico_geral: 30,
    demais: 40,
    anuidadeTimes: "31,20",
    dateTime: "",
  },
};

// Criação do Contexto
const GlobalStateContext = createContext<{
  state: GlobalState;
  setCdtPrices: (cdtPrices: GlobalState["cdtPrices"]) => void;
}>({
  state: initialState,
  setCdtPrices: () => [{}],
});

// Provider do estado global
export const GlobalStateProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<GlobalState>(initialState);

  // Funções para atualizar o estado global
  const setCdtPrices = (cdtPrices: GlobalState["cdtPrices"]) => {
    setState((prevState) => ({
      ...prevState,
      cdtPrices,
    }));
  };

  return (
    <GlobalStateContext.Provider value={{ state, setCdtPrices }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

// Hook personalizado para usar o estado global
export const useGlobalState = () => useContext(GlobalStateContext);
