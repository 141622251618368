const cepToMaskRegex = /(\d{5})(\d{1,3}).*/;
const phoneToMaskRegex = /(\d{2})(\d{4})(\d{1,4}).*/;
const cellPhoneToMaskRegex = /(\d{2})(\d{5})(\d{1,4}).*/;
const firtsGroupPhoneToMaskRegex = /(\d{2})(\d{1,4}).*/;

export const cepMask = (cep: string) => {
  const val = cep.replace(/\D/gi, '')
  if (val.length < 5) return val;
  return val.replace(cepToMaskRegex, '$1-$2');
}

export const phoneMask = (phone: string) => {
  const val = phone.replace(/\D/gi, '')
  if (val.length <= 2) return `(${val}`;
  if (val.length >= 3 && val.length < 6) return `(${val.substring(0,2)}) ${val.substring(2)}`;
  if (val.length <= 6) {
    return val.replace(firtsGroupPhoneToMaskRegex, '($1) $2');
  }
  if (val.length >= 11) {
    return val.replace(cellPhoneToMaskRegex, '($1) $2-$3');
  }
  return val.replace(phoneToMaskRegex, '($1) $2-$3')
}

export const cpfMask = (cpf: string) => {
  const val = cpf.replace(/[^\d]/gi, '')
  if(cpf.length <= 6){
    cpf = val.replace(/(\d{3})(\d{1,3})/g, '$1.$2');  
  }else if(cpf.length <= 9){
    cpf = val.replace(/(\d{3})(\d{3})(\d{1,3})/g, '$1.$2.$3');
  }else{
    cpf = val.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2}).*/g, "$1.$2.$3-$4");
  }
  return cpf;
  
}
