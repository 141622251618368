import { ReactElement } from "react";
import { Col, Container, Row } from "react-bootstrap";
import amorSaude from "./assets/logo-branco-amorsaude.svg";
import drogasil from "./assets/logo-branco-drogasil.svg";
import raia from "./assets/logo-branco-raia.svg";
import refuturiza from "./assets/logo-branco-refuturiza.svg";
import ultragaz from "./assets/logo-ultragaz.svg";
import { useGlobalState } from "../../../context/GlobalStateContext";

export function ParceirosSection(): ReactElement {
  const { state } = useGlobalState();
  return (
    <Container>
      <Row>
        <Col md="2" xs="6">
          <img src={amorSaude} alt="logo amor e saúde" />
          <p>
            consulta a partir
            <br />
            de <span>R$ {state.cdtPrices.clinico_geral}</span>
          </p>
        </Col>
        <Col md="2" xs="6">
          <img src={drogasil} alt="logo Drogasil" />
          <p>
            até <span>35%</span> em
            <br />
            medicamentos
          </p>
        </Col>
        <Col md="2" xs="6">
          <img src={raia} alt="Logo Droga Raia" />
          <p>
            até <span>35%</span> em
            <br />
            medicamentos
          </p>
        </Col>
        <Col md="2" xs="6">
          <img src={ultragaz} alt="Logo Ultragaz" />
          <p>
            desconto de 12%
            <br /> no botijão de gás
          </p>
        </Col>
        <Col md="2" xs="6">
          <img src={refuturiza} alt="Logo Refuturiza" />
          <p>
            <span>R$ 12,99</span> por mês <br /> e R$2 de cashback
          </p>
        </Col>
      </Row>
    </Container>
  );
}
