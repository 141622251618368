import axios from "axios";
import { Prices } from "../utils/prices";

const URI = "https://api.checkout.global.cartaodetodos.com.br/servico/18";

export async function getPricesCdt(): Promise<Prices> {
  try {
    const { data } = await axios.get(`${URI}`, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "LuUbARo0sQ4Nfmm6iZqNc1uPEVRCttCF1jnnEM1u",
      },
    });

    return data;
  } catch (e) {
    return {
      anuidadeTimes: "0",
      clinico_geral: 0,
      dateTime: "",
      demais: 0,
      valor: "0",
    };
  }
}
