import styled from "styled-components";


export const Beneficios = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding-bottom: 54px;
    padding-top: 54px;
`

export const SectionTitle = styled.h1`
    color: var(--btn-primary-bg);
    text-align: center;
    font-family: Ubuntu;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 32px;
    text-transform: uppercase;

    @media (max-width: 520px) {
        font-size: 24px;
        line-height: 36px; /* 150% */
    }
`

export const BeneficiosContainer = styled.div`
    margin: 0 auto;
    max-width: 892px;
    width: 100%;

    @media (max-width: 900px) {
        padding-left: 42px;
        padding-right: 42px;
    }
    
    @media (max-width: 520px) {
        overflow-x: scroll;
        width: 100%;
    }

    & > div {
        display: grid;
        gap: 32px;
        grid-template-columns: repeat(3, 1fr);

        @media (max-width: 894px) {
            grid-template-columns: repeat(2, 1fr);
        }
    
        @media (max-width: 520px) {
            display: flex;
            width: min-content;
        }
    }
`

export const Card = styled.div`
    align-items: flex-start;
    aspect-ratio: 1;
    background: #FFFFFF;
    border: 1px solid var(--btn-primary-bg);
    border-radius: 16px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: flex-start;
    padding: 32px;

    @media (max-width: 520px) {
        aspect-ratio: 212 / 187;
        box-sizing: content-box;
        min-width: 212px;
    }
`

export const Logo = styled.img`
    display: block;
    height: auto;
    max-width: 100%;
`

export const Title = styled.h1`
    color: #231F20;
    font-family: Ubuntu;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
`

export const Paragraph = styled.h1`
    color: #636365;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

export const CTAButton = styled.a`
    border-radius: 31px;
    background: var(--btn-primary-bg);
`

export const CTALink = styled.a`
    margin-top: 19px;

    @media (max-width: 520px) {
        font-size: 20px;
        width: 328px;
    }

    button {
        background-color: var(--red);
        color: var(--white);
        padding: 9px 27px;

        @media (max-width: 520px) {
            padding: 10px 27px;
            width: 100%;
        }
    }
`